import { nullable, z } from 'zod'

import { companyApi, customerTag } from 'app/types/zod/common/company'
import { externalIntegration } from 'app/types/zod/common/external-integrations'
import { globalApiKeyApi } from 'app/types/zod/common/global-apikey'
import { moNumber } from 'app/types/zod/common/mo-number'
import {
  datetimeStringWithOffset,
  integer,
  nonEmptyString,
  nullableString,
} from 'app/types/zod/utils'

export const integrationResponse = z
  .object({
    id: z.string().uuid(),
    created: datetimeStringWithOffset,
    api_keys: z.array(globalApiKeyApi),
    customer: companyApi,
    url: nonEmptyString,
    expiry_time: z.object({ milliseconds: integer }),
    external_integration: externalIntegration,
  })
  .describe('integrationResponse')

export const integration = integrationResponse
  .extend({
    expiry_time: integer,
  })
  .describe('integration')

export type Integration = z.infer<typeof integration>

export const integrationDetails = z
  .object({
    id: z.string().uuid(),
    enabled: z.boolean(),
    sender: nullable(nonEmptyString),
    connected: nullable(datetimeStringWithOffset),
    customer: companyApi,
    customer_tag: z.array(customerTag),
    external_integration: externalIntegration,
    integration_name: nullableString,
    created: datetimeStringWithOffset,
  })
  .describe('integrationDetails')

export type IntegrationDetails = z.infer<typeof integrationDetails>

const integrationListItem = z
  .object({
    id: z.string().uuid(),
    enabled: z.boolean(),
    connected: z.nullable(datetimeStringWithOffset),
    sender: nullableString,
    customer: companyApi,
    customer_tag: z.array(customerTag),
    external_integration: externalIntegration,
    integration_name: nullableString,
    created: datetimeStringWithOffset,
  })
  .strict()
  .describe('integrationListItem')

export type IntegrationListItem = z.infer<typeof integrationListItem>

export const integrationList = z
  .array(integrationListItem)
  .describe('integrationList')

export const integrationSubscription = z.object({
  id: integer,
  subscription_id: nonEmptyString,
  endpoint_type: z.enum(['MT', 'MO', 'DR']),
  endpoint_url: nonEmptyString,
  created: datetimeStringWithOffset,
  number: moNumber,
})

export type IntegrationSubscription = z.infer<typeof integrationSubscription>
