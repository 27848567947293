import { z } from 'zod'

import { ALPHANUMERIC_REGEX, requiredMsg } from 'app/common/utils'
import { statusEnum } from 'app/types/zod/common/Enums'
import {
  datetimeStringWithOffset,
  integer,
  nonEmptyString,
} from 'app/types/zod/utils'

const customerSchema = z.object({
  id: integer,
  name: nonEmptyString,
  created: datetimeStringWithOffset,
})

export const senderIdApiItem = z.object({
  id: integer,
  action: z.string(),
  created: datetimeStringWithOffset,
  sender_id: nonEmptyString,
  status: statusEnum,
  whitelisted_sender_id: z.object({
    id: integer,
    registeredsenderid: integer,
    routerules: z.array(z.any()),
    customers: z.array(customerSchema),
  }),
})

export const senderIdSelectorItem = z.object({
  id: integer,
  sender_id: nonEmptyString,
})

export const senderIdFormValues = z.object({
  id: integer.optional(),
  senderid: z
    .string()
    .trim()
    .refine((val) => val.length > 0, {
      message: requiredMsg,
    })
    .pipe(
      z
        .string()
        .min(2, 'Sender ID has to be 2 - 11 characters.')
        .max(11, 'Sender ID has to be 2 - 11 characters.')
        .regex(
          ALPHANUMERIC_REGEX,
          'The Sender ID contains characters that are not allowed. Allowed characters include letters A-Ö, numbers 0-9, hyphen and whitespace. Please remove or replace the invalid characters and try again.'
        )
        .regex(
          /^(?:(?!\s{2,}).)*$/,
          'Blank character can not be followed by another blank.'
        )
    ),
  status: statusEnum,
  actions: nonEmptyString,
  whiteListId: z.number().optional(),
  customerIds: z.array(integer),
})

export const defaultActionsSchema = nonEmptyString

export type SenderIdSelectorItemType = z.infer<typeof senderIdSelectorItem>

export type SenderIdFormValuesType = z.infer<typeof senderIdFormValues>

export type SenderIdApiItemType = z.infer<typeof senderIdApiItem>

export type SenderIdApiItem = z.infer<typeof senderIdApiItem>
