import { lazy, ReactNode, Suspense } from 'react'
import { Route, RouteProps, Router, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import { ModuleName } from 'app/types/flow'
import Loading from './common/loading/Loading'
import { BreadcrumbItem } from './home/Home'
import { Contact, Group } from './types/app'
import { applyGuards } from './guards'

export interface IRoute extends RouteProps {
  routes?: IRoute[]
  title?: string
  icon?: string
  guards?: { policy?: string; role?: string | string[]; redirect?: string }[]
  routeProps?: { inGroup?: boolean; system?: boolean; modulename?: ModuleName }
}

export interface RouteExtraParams {
  onEnter?: () => void
  setBreadcrumb?: (items: BreadcrumbItem[]) => ReactNode
  contact?: Contact
  group?: Group
}

export const routes: IRoute[] = [
  {
    exact: true,
    path: '/login',
    component: lazy(() => import('./auth/login/Login')),
  },
  {
    exact: true,
    path: '/sso/:key/login',
    component: lazy(() => import('./auth/sso/Sso')),
  },
  {
    exact: true,
    path: '/login/twofactor',
    component: lazy(() => import('./auth/login/TwoFactor')),
  },
  {
    exact: true,
    path: '/forgot-password',
    component: lazy(() => import('./auth/forgot-password/ForgotPassword')),
  },
  {
    exact: true,
    path: '/forgot-password/complete',
    component: lazy(() => import('./auth/forgot-password/Complete')),
  },
  {
    exact: true,
    path: '/reset-password/:token',
    component: lazy(
      () => import('./auth/forgot-password/change-password/NewPassword')
    ),
  },
  {
    exact: true,
    path: '/forgot-password/done',
    component: lazy(
      () => import('./auth/forgot-password/change-password/Done')
    ),
  },
  {
    exact: true,
    path: '/confirm-invitation/:token',
    component: lazy(() => import('./auth/invitation/confirm/ActivateAccount')),
  },
  {
    exact: true,
    path: '/account-activation/done',
    component: lazy(() => import('./auth/invitation/confirm/ActivationDone')),
  },
  {
    exact: true,
    path: '/logout',
    component: lazy(() => import('./auth/logout/Logout')),
  },
  {
    path: '/media/view',
    component: lazy(() => import('./tools/media/MediaViewer')),
    routes: [
      {
        exact: true,
        path: '/media/view/:mediaType/:url',
        component: lazy(() => import('./tools/media/OriginalMedia')),
      },
    ],
  },
  {
    path: '/',
    component: lazy(() => import('./home/Home')),
    routes: [
      {
        exact: true,
        path: '/',
        guards: [{ policy: 'QA_INDEX' }],
        component: lazy(() => import('./dashboard/Dashboard')),
      },
      {
        exact: true,
        path: '/profile',
        guards: [{ policy: 'QAFS_MYPROFILE' }],
        component: lazy(() => import('./profile/Profile')),
      },
      {
        exact: true,
        path: '/system/accounts',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/accounts/Accounts')),
      },
      {
        path: '/system/accounts/:id',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/accounts/Account')),
        routes: [
          {
            exact: true,
            path: '/system/accounts/:id',
            component: lazy(() => import('./system/accounts/User')),
            title: 'User',
            icon: 'qf qf-user',
          },
          {
            exact: true,
            path: '/system/accounts/:id/policy-groups',
            component: lazy(() => import('./system/policies/PolicyGroups')),
            icon: 'qf qf-organization',
          },
          // {
          //   exact: true,
          //   path: '/system/accounts/:id/customers',
          //   component: lazy(() => import('./system/customers/Customers')),
          //   icon: 'qf qf-business-area'
          // }
        ],
      },
      {
        exact: true,
        path: '/system/ssoconnections',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/sso/SsoConnections')),
      },
      {
        exact: true,
        path: '/system/ssoconnections/:id',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/sso/SsoConnection')),
      },
      {
        exact: true,
        path: '/system/policies',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/policies/Policies')),
      },
      {
        exact: true,
        path: '/system/policy-groups',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/policies/PolicyGroups')),
      },
      {
        path: '/system/policy-groups/:id',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/policies/PolicyGroup')),
        routes: [
          {
            exact: true,
            path: '/system/policy-groups/:id',
            component: lazy(() => import('./system/policies/Group')),
            title: 'Policy group',
            icon: 'qf qf-organization',
          },
          {
            exact: true,
            path: '/system/policy-groups/:id/policies',
            component: lazy(() => import('./system/policies/Policies')),
            title: 'Policies',
            icon: 'qf qf-security-guard',
          },
          {
            exact: true,
            path: '/system/policy-groups/:id/accounts',
            component: lazy(() => import('./system/accounts/Accounts')),
            title: 'Accounts',
            icon: 'qf qf-group',
          },
        ],
      },
      {
        exact: true,
        path: '/system/audit-log',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/audit-log/AuditLog')),
      },
      {
        exact: true,
        path: '/system/customers',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/customers/Customers')),
      },
      {
        path: '/system/customers/:id',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/customers/Customer')),
        routes: [
          {
            exact: true,
            path: '/system/customers/:id',
            component: lazy(() => import('./system/customers/Company')),
            title: 'General',
            icon: 'qf qf-apartment-a',
          },
          {
            exact: true,
            path: '/system/customers/:id/users',
            component: lazy(() => import('./system/accounts/Accounts')),
            title: 'Users',
            icon: 'qf qf-group',
          },
          {
            exact: true,
            path: '/system/customers/:id/mo-numbers',
            component: lazy(() => import('./system/mo-numbers/MoNumbers')),
            title: 'MO Numbers',
            icon: 'qf qf-meatball-ui',
          },
          {
            exact: true,
            path: '/system/customers/:id/senderid',
            component: lazy(() => import('./system/senderid/Senderids')),
            title: 'SMS Sender ID',
          },
          {
            exact: true,
            path: '/system/customers/:id/messaging-suite',
            component: lazy(() => import('./system/customers/Applications')),
            title: 'Messaging suite',
            icon: 'qf qf-rocket',
          },
          {
            exact: true,
            path: '/system/customers/:id/storage',
            component: lazy(() => import('./system/customers/MediaLibrary')),
            title: 'Storage',
            icon: 'qf qf-stack-folders',
          },
          {
            exact: true,
            path: '/system/customers/:id/rcs/:agentId',
            component: lazy(() => import('./system/customers/rcs/RcsManager')),
            title: 'RCS',
            icon: 'qf qf-chat-thread',
          },
          {
            exact: true,
            path: '/system/customers/:id/connections',
            component: lazy(() => import('./system/customers/Connections')),
            title: 'Connections',
            icon: 'qf qf-arrow-up-down-circle',
          },
          {
            exact: true,
            path: '/system/customers/:id/billing',
            component: lazy(() => import('./system/customers/Billing')),
            title: 'Billing',
            icon: 'qf qf-invoice',
          },
          {
            exact: true,
            path: '/system/customers/:id/cleanup-events',
            component: lazy(
              () =>
                import('./system/data-cleanup/customer/CustomerCleanupEvents')
            ),
            title: 'Cleanup events',
            icon: 'qf qf-project-file-information',
          },
          {
            exact: true,
            path: '/system/customers/:id/cleanup-routines/:itemId',
            component: lazy(
              () => import('./system/data-cleanup/customer/CustomerCleanups')
            ),
            title: 'Cleanup routines',
            icon: 'qf qf-project-file-cross',
          },
        ],
      },
      {
        exact: true,
        path: '/system/billing/groups',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/billing/ProductGroups')),
      },
      {
        exact: true,
        path: '/system/billing/items',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/billing/BillingItems')),
      },
      {
        exact: true,
        path: '/system/billing/invoices',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/billing/Invoices')),
      },
      {
        exact: true,
        path: '/system/billing/invoices/:id',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/billing/Invoice')),
      },
      {
        exact: true,
        path: '/system/billing',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/billing/Products')),
      },
      {
        exact: true,
        path: '/system/mo-numbers',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/mo-numbers/MoNumbers')),
      },
      {
        exact: true,
        path: '/system/mo-numbers/:id',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/mo-numbers/MoNumber')),
      },
      {
        exact: true,
        path: '/system/routing/rules',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/routing/RoutingRules')),
      },
      {
        exact: true,
        path: '/system/routing/rules/:id',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/routing/RoutingRule')),
      },
      {
        exact: true,
        path: '/system/rcs-agents',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/rcs/Agents')),
      },
      {
        exact: true,
        path: '/system/storage',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/storage/Storages')),
      },
      {
        exact: true,
        path: '/system/senderids',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/senderid/Senderids')),
      },
      {
        exact: true,
        path: '/system/senderids/:id',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/senderid/SenderId')),
      },
      {
        exact: true,
        path: '/router/settings',
        guards: [{ policy: 'QAF_INTERFACE_SETTINGS' }],
        component: lazy(() => import('./service/router/Settings')),
      },
      {
        exact: true,
        path: '/router/routings',
        guards: [{ policy: 'QAF_ROUTING_SETTINGS' }],
        component: lazy(() => import('./service/router/Routings')),
      },
      {
        exact: true,
        path: '/router/routings/:id',
        guards: [{ policy: 'QAF_ROUTING_SETTINGS' }],
        component: lazy(() => import('./service/router/RoutingView')),
      },
      {
        exact: true,
        path: '/router/resources',
        guards: [{ policy: 'QAF_ROUTING_RESOURCES' }],
        component: lazy(() => import('./service/router/Resources')),
      },
      {
        exact: true,
        path: '/contacts',
        guards: [{ policy: 'QAF_CONTACTS' }],
        component: lazy(() => import('./tools/contact/Contacts')),
      },
      {
        path: '/contacts/:id',
        component: lazy(() => import('./tools/contact/Contact')),
        routes: [
          {
            exact: true,
            path: '/contacts/:id',
            component: lazy(() => import('./tools/contact/Information')),
            title: 'Info',
            icon: 'qf qf-user-information',
          },
          {
            exact: true,
            path: '/contacts/:id/groups',
            component: lazy(() => import('./tools/contact/ContactGroups')),
            title: 'Groups',
            icon: 'qf qf-group',
          },
          {
            path: '/contacts/:id/messages',
            component: lazy(() => import('./tools/tracking/Nested')),
            title: 'Messages',
            icon: 'qf qf-search-list',
            routes: [
              {
                path: '/contacts/:id/messages',
                component: lazy(
                  () =>
                    import('./service/applications/common/list-views/Messages')
                ),
                exact: true,
                title: 'SMS logs',
                icon: 'qf qf-search-list',
              },
              {
                path: '/contacts/:id/messages/rcs',
                component: lazy(() => import('./tools/tracking/RcsLogs')),
                exact: true,
                title: 'RCS logs',
                icon: 'qf qf-chat-thread',
              },
            ],
          },
          {
            exact: true,
            path: '/contacts/:id/events',
            guards: [
              { policy: 'QAFS_APPLICATION_EVENTS', redirect: '/contacts' },
            ],
            component: lazy(
              () => import('./service/applications/events/Events')
            ),
            title: 'Events',
            icon: 'qf qf-events',
          },
          {
            exact: true,
            path: '/contacts/:id/notes',
            component: lazy(() => import('./tools/contact/ContactNotes')),
            title: 'Notes',
            icon: 'qf qf-notes-list-a',
          },
        ],
      },
      {
        exact: true,
        path: '/groups',
        guards: [{ policy: 'QAF_GROUPS' }],
        component: lazy(() => import('./tools/contact/ContactGroups')),
      },
      {
        path: '/groups/:id',
        component: lazy(() => import('./tools/contact/ContactGroup')),
        routes: [
          {
            exact: true,
            path: '/groups/:id',
            guards: [{ policy: 'QAFS_GROUP_MEMBERS', redirect: '/groups' }],
            component: lazy(() => import('./tools/contact/Contacts')),
            title: 'Contacts',
            icon: 'qf qf-user',
            routeProps: {
              inGroup: true,
            },
          },
          {
            path: '/groups/:id/messages',
            component: lazy(() => import('./tools/tracking/Nested')),
            guards: [{ policy: 'QAFS_GROUP_MESSAGES', redirect: '/groups' }],
            title: 'Messages',
            icon: 'qf qf-search-list',
            routes: [
              {
                path: '/groups/:id/messages',
                component: lazy(
                  () =>
                    import('./service/applications/common/list-views/Messages')
                ),
                exact: true,
                title: 'SMS logs',
                icon: 'qf qf-search-list',
              },
              {
                path: '/groups/:id/messages/rcs',
                component: lazy(() => import('./tools/tracking/RcsLogs')),
                exact: true,
                title: 'RCS logs',
                icon: 'qf qf-chat-thread',
              },
            ],
          },
          {
            exact: true,
            path: '/groups/:id/events',
            guards: [
              { policy: 'QAFS_APPLICATION_EVENTS', redirect: '/groups' },
            ],
            component: lazy(
              () => import('./service/applications/events/Events')
            ),
            title: 'Events',
            icon: 'qf qf-events',
          },
          {
            exact: true,
            path: '/groups/:id/information',
            guards: [{ policy: 'QAFS_GROUP_INFO', redirect: '/groups' }],
            component: lazy(() => import('./tools/contact/Group')),
            title: 'Information',
            icon: 'qf qf-group',
          },
        ],
      },
      {
        exact: true,
        path: '/unsubscription',
        component: lazy(
          () => import('./tools/unsubscription/UnsubscriptionView')
        ),
      },
      {
        exact: true,
        path: '/unsubscription/:id/copy',
        component: lazy(
          () => import('./tools/unsubscription/UnsubscriptionView')
        ),
      },
      {
        path: '/unsubscription/details/:id',
        guards: [{ policy: 'QAF_CONTACTS' }],
        component: lazy(
          () => import('./tools/unsubscription/UnsubscriptionDetails')
        ),
        routes: [
          {
            exact: true,
            path: '/unsubscription/details/:id',
            component: lazy(
              () => import('./tools/unsubscription/UnsubscriptionView')
            ),
            title: 'Mo number',
            icon: 'qf qf-block-sign',
          },
          {
            exact: true,
            path: '/unsubscription/details/:id/received',
            title: 'Received messages',
            component: lazy(
              () =>
                import(
                  './tools/unsubscription/UnsubscriptionDetails/ReceivedMessages'
                )
            ),
            icon: 'qf qf-arrow-bottom-circle',
            routeProps: { modulename: ModuleName.UNSUBSCRIBE },
          },
          {
            exact: true,
            path: '/unsubscription/details/:id/sent',
            title: 'Sent messages',
            component: lazy(
              () =>
                import(
                  './tools/unsubscription/UnsubscriptionDetails/SentMessages'
                )
            ),
            icon: 'qf qf-arrow-top-circle',
            routeProps: { modulename: ModuleName.UNSUBSCRIBE },
          },
        ],
      },
      {
        path: '/unsubscription/manage',
        component: lazy(
          () => import('./tools/unsubscription/UnsubscriptionTabbed')
        ),
        guards: [{ policy: 'QAF_CONTACTS' }],
        routes: [
          {
            exact: true,
            path: '/unsubscription/manage',
            component: lazy(() => import('./tools/unsubscription/MoNumbers')),
            title: 'Receiving numbers',
            icon: 'qf qf-meatball-ui',
          },
          {
            exact: true,
            path: '/unsubscription/manage/contacts',
            component: lazy(() => import('./tools/unsubscription/Contacts')),
            title: 'Unsubscribed contacts',
            icon: 'qf qf-user',
          },
        ],
      },
      {
        exact: true,
        path: '/templates',
        guards: [{ policy: 'QAF_MESSAGE_TEMPLATES' }],
        component: lazy(() => import('./service/applications/main/Templates')),
      },
      {
        exact: true,
        path: '/templates/rcs/add',
        guards: [{ policy: 'QAF_MESSAGE_TEMPLATES' }],
        component: lazy(
          () => import('./service/applications/main/RcsTemplate')
        ),
      },
      {
        exact: true,
        path: '/templates/rcs/:id(\\d+)',
        guards: [{ policy: 'QAF_MESSAGE_TEMPLATES' }],
        component: lazy(
          () => import('./service/applications/main/RcsTemplate')
        ),
      },
      {
        exact: true,
        path: '/templates/:id',
        guards: [{ policy: 'QAF_MESSAGE_TEMPLATES' }],
        component: lazy(() => import('./service/applications/main/Template')),
      },
      {
        exact: true,
        path: '/apikeys/:id(\\d+)',
        guards: [{ policy: 'QAFS_MANAGEMENT_APIKEYS' }],
        component: lazy(() => import('./tools/apikeys/ApiKey')),
      },
      {
        path: '/apikeys/manage',
        component: lazy(() => import('./common/tabbed/Tabbed')),
        guards: [{ policy: 'QAFS_MANAGEMENT_APIKEYS' }],
        routes: [
          {
            exact: true,
            path: '/apikeys/manage',
            component: lazy(() => import('./tools/apikeys/ApiKeys')),
            title: 'API keys',
            icon: 'qf qf-world-network',
          },
          {
            exact: true,
            path: '/apikeys/manage/singlepurpose',
            component: lazy(() => import('./tools/apikeys/SinglePurposeKeys')),
            title: 'Event and routing rule specific keys',
            icon: 'qf qf-task-list',
          },
        ],
      },
      {
        exact: true,
        path: '/media',
        guards: [{ policy: 'QA_MANAGEMENT_STORAGE' }],
        component: lazy(() => import('./tools/media/MediaLibrary')),
      },
      {
        path: '/integrations/details/:id',
        guards: [{ policy: 'QA_EXTERNAL_INTEGRATIONS' }],
        component: lazy(
          () => import('./tools/integrations/ZapierIntegrationDetails')
        ),
        routes: [
          {
            exact: true,
            path: '/integrations/details/:id',
            title: 'Basic Info',
            component: lazy(
              () =>
                import(
                  './tools/integrations/ZapierIntegrationDetails/BasicInfo'
                )
            ),
            icon: 'qf qf-task-list',
          },
          {
            exact: true,
            path: '/integrations/details/:id/sent',
            title: 'Sent messages',
            component: lazy(
              () =>
                import(
                  './tools/integrations/ZapierIntegrationDetails/SentMessages'
                )
            ),
            icon: 'qf qf-arrow-top-circle',
          },
          {
            exact: true,
            path: '/integrations/details/:id/received',
            title: 'Received messages',
            component: lazy(
              () =>
                import(
                  './tools/integrations/ZapierIntegrationDetails/ReceivedMessages'
                )
            ),
            icon: 'qf qf-arrow-bottom-circle',
          },
        ],
      },
      {
        path: '/integrations/manage',
        component: lazy(
          () => import('./tools/integrations/IntegrationsTabbed')
        ),
        guards: [{ policy: 'QA_EXTERNAL_INTEGRATIONS' }],
        routes: [
          {
            exact: true,
            path: '/integrations/manage',
            component: lazy(
              () => import('./tools/integrations/ConnectedIntegrations')
            ),
            title: 'Integrations in use',
            icon: 'qf qf-arrow-up-down-circle',
          },
          {
            exact: true,
            path: '/integrations/manage/applications',
            component: lazy(
              () => import('./tools/integrations/ExternalIntegrations')
            ),
            title: 'Available applications',
            icon: 'qf qf-two-grids',
          },
        ],
      },
      {
        exact: true,
        path: '/sender-ids',
        guards: [{ policy: 'QA_SENDER_MANAGEMENT' }],
        component: lazy(
          () => import('./tools/sender-management/SenderManagement')
        ),
      },

      {
        path: '/system/data-cleanup/system-routines/:id(\\d+)',
        exact: true,
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(
          () => import('./system/data-cleanup/system/SystemCleanupRoutine')
        ),
      },
      {
        path: '/system/data-cleanup/system',
        component: lazy(() => import('./common/tabbed/Tabbed')),
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        routes: [
          {
            path: '/system/data-cleanup/system/events',
            exact: true,
            component: lazy(
              () => import('./system/data-cleanup/system/SystemCleanupEvents')
            ),
            title: 'System cleanup events',
            icon: 'qf qf-project-file-information',
          },
          {
            path: '/system/data-cleanup/system/routines',
            exact: true,
            component: lazy(
              () => import('./system/data-cleanup/system/SystemCleanups')
            ),
            title: 'System cleanup routines',
            icon: 'qf qf-project-file-cross',
          },
        ],
      },
      {
        path: '/system/data-cleanup/customer',
        component: lazy(() => import('./common/tabbed/Tabbed')),
        guards: [{ role: ['sysowner', 'systemAdmin'] }],
        routes: [
          {
            path: '/system/data-cleanup/customer/events',
            exact: true,
            component: lazy(
              () =>
                import('./system/data-cleanup/customer/CustomerCleanupEvents')
            ),
            title: 'Customer cleanup events',
            icon: 'qf qf-project-file-information',
          },
          {
            path: '/system/data-cleanup/customer/routines',
            exact: true,
            component: lazy(
              () => import('./system/data-cleanup/customer/CustomerCleanups')
            ),
            title: 'Customer cleanup routines',
            icon: 'qf qf-project-file-cross',
          },
        ],
      },
      {
        path: '/tracking/logs',
        guards: [{ policy: 'QAF_LOGS' }],
        component: lazy(
          () => import('./tools/tracking/MessageLogsMaybeTabbed')
        ),
        routes: [
          {
            path: '/tracking/logs',
            exact: true,
            component: lazy(() => import('./tools/tracking/MessageLogs')),
            title: 'SMS log',
            icon: 'qf qf-search-list',
          },
          {
            path: '/tracking/logs/rcs',
            exact: true,
            component: lazy(() => import('./tools/tracking/RcsLogs')),
            title: 'RCS log',
            icon: 'qf qf-chat-thread',
          },
        ],
      },
      {
        path: '/tracking/reports',
        guards: [{ policy: 'QAF_REPORTS' }],
        component: lazy(() => import('./tools/tracking/Reports')),
        routes: [
          {
            exact: true,
            path: '/tracking/reports',
            guards: [{ policy: 'QAFS_REPORTS_BASIC', redirect: '/reports' }],
            component: lazy(() => import('./tools/tracking/Numerical')),
            title: 'Numerical',
            icon: 'qf qf-line-chart',
          },
          {
            exact: true,
            path: '/tracking/reports/graphical',
            guards: [
              { policy: 'QAFS_REPORTS_STATISTICS', redirect: '/reports' },
            ],
            component: lazy(() => import('./tools/tracking/Graphical')),
            title: 'Graphical',
            icon: 'qf qf-bubble-chart',
          },
        ],
      },
      {
        exact: true,
        path: '/settings/accounts',
        guards: [{ policy: 'QA_USERS' }],
        component: lazy(() => import('./service/accounts/Accounts')),
      },
      {
        exact: true,
        path: '/settings/accounts/:id',
        guards: [{ policy: 'QA_USERS' }],
        component: lazy(() => import('./service/accounts/User')),
      },
      {
        exact: true,
        path: '/applications',
        component: lazy(
          () => import('./service/applications/main/Applications')
        ),
      },
      {
        exact: true,
        path: '/applications/smsfeedback',
        component: lazy(
          () =>
            import(
              './service/applications/feedback-and-inquiry/FeedbackAndInquiry'
            )
        ),
      },
      {
        exact: true,
        path: '/applications/events/smsfeedback/:id/copy',
        component: lazy(
          () =>
            import(
              './service/applications/feedback-and-inquiry/FeedbackAndInquiry'
            )
        ),
      },
      {
        exact: true,
        path: '/applications/smsfeedback/:id/draft',
        component: lazy(
          () =>
            import(
              './service/applications/feedback-and-inquiry/FeedbackAndInquiry'
            )
        ),
      },
      {
        path: '/applications/events/smsfeedback/:id',
        component: lazy(() => import('./service/applications/events/Details')),
        routes: [
          {
            exact: true,
            path: '/applications/events/smsfeedback/:id',
            component: lazy(
              () =>
                import(
                  './service/applications/feedback-and-inquiry/FeedbackAndInquiryView'
                )
            ),
            title: 'smsfeedback',
            icon: 'qf qf-arrow-left-right',
          },
          {
            exact: true,
            path: '/applications/events/smsfeedback/:id/tasks',
            component: lazy(
              () => import('./service/applications/common/list-views/Tasks')
            ),
            title: 'Tasks',
            icon: 'qf qf-task-list',
          },
          {
            exact: true,
            path: '/applications/events/smsfeedback/:id/messages',
            component: lazy(
              () => import('./service/applications/common/list-views/Messages')
            ),
            title: 'Messages',
            icon: 'qf qf-search',
          },
        ],
      },
      {
        exact: true,
        path: '/applications/easydialog',
        component: lazy(() => import('./service/applications/dialog/Dialog')),
      },
      {
        exact: true,
        path: '/applications/events/easydialog/:id/copy',
        component: lazy(() => import('./service/applications/dialog/Dialog')),
      },
      {
        exact: true,
        path: '/applications/easydialog/:id/draft',
        component: lazy(() => import('./service/applications/dialog/Dialog')),
      },
      {
        path: '/applications/events/easydialog/:id',
        component: lazy(() => import('./service/applications/events/Details')),
        routes: [
          {
            exact: true,
            path: '/applications/events/easydialog/:id',
            component: lazy(
              () => import('./service/applications/dialog/DialogView')
            ),
            title: 'easydialog',
            icon: 'qf qf-chat-thread',
          },
          {
            exact: true,
            path: '/applications/events/easydialog/:id/tasks',
            component: lazy(
              () => import('./service/applications/common/list-views/Tasks')
            ),
            title: 'Tasks',
            icon: 'qf qf-task-list',
          },
          {
            exact: true,
            path: '/applications/events/easydialog/:id/messages',
            component: lazy(
              () => import('./service/applications/common/list-views/Messages')
            ),
            title: 'Messages',
            icon: 'qf qf-search',
          },
          {
            exact: true,
            path: '/applications/events/easydialog/:id/reports',
            component: lazy(
              () => import('./service/applications/common/list-views/Reports')
            ),
            title: 'Reports',
            icon: 'qf qf-file-information',
          },
          {
            exact: true,
            path: '/applications/events/easydialog/:id/statistics',
            component: lazy(
              () => import('./service/applications/dialog/DialogStatistics')
            ),
            title: 'Statistics',
            icon: 'qf qf-bubble-chart',
          },
        ],
      },
      {
        exact: true,
        path: '/applications/calltoaction',
        component: lazy(
          () => import('./service/applications/call-to-action/CallToAction')
        ),
      },
      {
        exact: true,
        path: '/applications/events/calltoaction/:id/copy',
        component: lazy(
          () => import('./service/applications/call-to-action/CallToAction')
        ),
      },
      {
        exact: true,
        path: '/applications/calltoaction/:id/draft',
        component: lazy(
          () => import('./service/applications/call-to-action/CallToAction')
        ),
      },
      {
        path: '/applications/events/calltoaction/:id',
        component: lazy(() => import('./service/applications/events/Details')),
        routes: [
          {
            exact: true,
            path: '/applications/events/calltoaction/:id',
            component: lazy(
              () =>
                import('./service/applications/call-to-action/CallToActionView')
            ),
            title: 'calltoaction',
            icon: 'qf qf-announcement',
          },
          {
            exact: true,
            path: '/applications/events/calltoaction/:id/tasks',
            component: lazy(
              () => import('./service/applications/common/list-views/Tasks')
            ),
            title: 'Tasks',
            icon: 'qf qf-task-list',
          },
          {
            exact: true,
            path: '/applications/events/calltoaction/:id/messages',
            component: lazy(
              () => import('./service/applications/common/list-views/Messages')
            ),
            title: 'Messages',
            icon: 'qf qf-search',
          },
          {
            exact: true,
            path: '/applications/events/calltoaction/:id/reports',
            component: lazy(
              () => import('./service/applications/common/list-views/Reports')
            ),
            title: 'Reports',
            icon: 'qf qf-file-information',
          },
        ],
      },
      {
        exact: true,
        path: '/applications/smsinbox',
        component: lazy(() => import('./service/applications/inbox/Inbox')),
      },
      {
        exact: true,
        path: '/applications/events/smsinbox/:id/copy',
        component: lazy(() => import('./service/applications/inbox/Inbox')),
      },
      {
        exact: true,
        path: '/applications/smsinbox/:id/draft',
        component: lazy(() => import('./service/applications/inbox/Inbox')),
      },
      {
        path: '/applications/events/smsinbox/:id',
        component: lazy(() => import('./service/applications/events/Details')),
        routes: [
          {
            exact: true,
            path: '/applications/events/smsinbox/:id',
            component: lazy(
              () => import('./service/applications/inbox/InboxView')
            ),
            title: 'smsinbox',
            icon: 'qf qf-mailbox',
          },
          {
            exact: true,
            path: '/applications/events/smsinbox/:id/messages',
            component: lazy(
              () => import('./service/applications/common/list-views/Messages')
            ),
            title: 'Messages',
            icon: 'qf qf-search',
          },
          {
            exact: true,
            path: '/applications/events/smsinbox/:id/folders',
            component: lazy(
              () => import('./service/applications/common/list-views/Folders')
            ),
            title: 'Folders',
            icon: 'qf qf-search',
          },
        ],
      },
      {
        exact: true,
        path: '/applications/sendsms',
        component: lazy(
          () => import('./service/applications/group-message/GroupMessage')
        ),
      },
      {
        exact: true,
        path: '/applications/events/sendsms/:id/copy',
        component: lazy(
          () => import('./service/applications/group-message/GroupMessage')
        ),
      },
      {
        exact: true,
        path: '/applications/sendsms/:id/draft',
        component: lazy(
          () => import('./service/applications/group-message/GroupMessage')
        ),
      },
      {
        path: '/applications/events/sendsms/:id',
        component: lazy(() => import('./service/applications/events/Details')),
        routes: [
          {
            exact: true,
            path: '/applications/events/sendsms/:id',
            component: lazy(
              () =>
                import('./service/applications/group-message/GroupMessageView')
            ),
            title: 'sendsms',
            icon: 'qf qf-email',
          },
          {
            exact: true,
            path: '/applications/events/sendsms/:id/tasks',
            component: lazy(
              () => import('./service/applications/common/list-views/Tasks')
            ),
            title: 'Tasks',
            icon: 'qf qf-task-list',
          },
          {
            exact: true,
            path: '/applications/events/sendsms/:id/messages',
            component: lazy(
              () => import('./service/applications/common/list-views/Messages')
            ),
            title: 'Messages',
            icon: 'qf qf-search',
          },
        ],
      },
      {
        exact: true,
        path: '/applications/quickmessage',
        component: lazy(
          () => import('./service/applications/quick-message/QuickMessage')
        ),
      },
      {
        exact: true,
        path: '/applications/events/quickmessage/:id/copy',
        component: lazy(
          () => import('./service/applications/quick-message/QuickMessage')
        ),
      },
      {
        exact: true,
        path: '/applications/quickmessage/:id/draft',
        component: lazy(
          () => import('./service/applications/quick-message/QuickMessage')
        ),
      },
      {
        path: '/applications/events/quickmessage/:id',
        component: lazy(() => import('./service/applications/events/Details')),
        routes: [
          {
            exact: true,
            path: '/applications/events/quickmessage/:id',
            component: lazy(
              () =>
                import('./service/applications/quick-message/QuickMessageView')
            ),
            title: 'quickmessage',
            icon: 'qf qf-sms',
          },
          {
            exact: true,
            path: '/applications/events/quickmessage/:id/tasks',
            component: lazy(
              () => import('./service/applications/common/list-views/Tasks')
            ),
            title: 'Tasks',
            icon: 'qf qf-task-list',
          },
          {
            exact: true,
            path: '/applications/events/quickmessage/:id/messages',
            component: lazy(
              () => import('./service/applications/common/list-views/Messages')
            ),
            title: 'Messages',
            icon: 'qf qf-search',
          },
        ],
      },
      {
        exact: true,
        path: '/applications/npssurvey',
        component: lazy(
          () => import('./service/applications/nps-survey/NpsSurvey')
        ),
      },
      {
        exact: true,
        path: '/applications/events/npssurvey/:id/copy',
        component: lazy(
          () => import('./service/applications/nps-survey/NpsSurvey')
        ),
      },
      {
        exact: true,
        path: '/applications/npssurvey/:id/draft',
        component: lazy(
          () => import('./service/applications/nps-survey/NpsSurvey')
        ),
      },
      {
        path: '/applications/events/npssurvey/:id',
        component: lazy(() => import('./service/applications/events/Details')),
        routes: [
          {
            exact: true,
            path: '/applications/events/npssurvey/:id',
            component: lazy(
              () => import('./service/applications/nps-survey/NpsSurveyView')
            ),
            title: 'npssurvey',
            icon: 'qf qf-chat-thread',
          },
          {
            exact: true,
            path: '/applications/events/npssurvey/:id/messages',
            component: lazy(
              () => import('./service/applications/common/list-views/Messages')
            ),
            title: 'Messages',
            icon: 'qf qf-search',
            routeProps: { modulename: ModuleName.NPS_SURVEY },
          },
          {
            exact: true,
            path: '/applications/events/npssurvey/:id/statistics',
            component: lazy(
              () => import('./service/applications/nps-survey/NpsGraphView')
            ),
            title: 'Statistics',
            icon: 'qf qf-bubble-chart',
          },
        ],
      },
      {
        exact: true,
        path: '/applications/groupsubscription',
        component: lazy(
          () =>
            import(
              './service/applications/group-subscription/GroupSubscriptionView'
            )
        ),
      },
      {
        exact: true,
        path: '/applications/events/groupsubscription/:id/copy',
        component: lazy(
          () =>
            import(
              './service/applications/group-subscription/GroupSubscriptionView'
            )
        ),
      },
      {
        path: '/applications/events/groupsubscription/:id',
        component: lazy(() => import('./service/applications/events/Details')),
        routes: [
          {
            exact: true,
            path: '/applications/events/groupsubscription/:id',
            component: lazy(
              () =>
                import(
                  './service/applications/group-subscription/GroupSubscriptionView'
                )
            ),
            title: 'groupsubscription',
            icon: 'qf qf-chat-thread',
          },
          {
            exact: true,
            path: '/applications/events/groupsubscription/:id/messages',
            component: lazy(
              () => import('./service/applications/common/list-views/Messages')
            ),
            title: 'Messages',
            icon: 'qf qf-search',
          },
          {
            exact: true,
            path: '/applications/events/groupsubscription/:id/statistics',
            component: lazy(
              () =>
                import(
                  './service/applications/group-subscription/GroupSubscriptionGraphView'
                )
            ),
            title: 'Statistics',
            icon: 'qf qf-bubble-chart',
          },
        ],
      },
      {
        exact: true,
        path: '/applications/modialog',
        component: lazy(
          () => import('./service/applications/mo-dialog/MoDialog')
        ),
      },
      {
        exact: true,
        path: '/applications/events/modialog/:id/copy',
        component: lazy(
          () => import('./service/applications/mo-dialog/MoDialog')
        ),
      },
      {
        exact: true,
        path: '/applications/modialog/:id/draft',
        component: lazy(
          () => import('./service/applications/mo-dialog/MoDialog')
        ),
      },
      {
        path: '/applications/events/modialog/:id',
        component: lazy(() => import('./service/applications/events/Details')),
        routes: [
          {
            exact: true,
            path: '/applications/events/modialog/:id',
            component: lazy(
              () => import('./service/applications/mo-dialog/MoDialogView')
            ),
            title: 'modialog',
            icon: 'qf qf-chat-thread',
          },
          {
            exact: true,
            path: '/applications/events/modialog/:id/tasks',
            component: lazy(
              () => import('./service/applications/common/list-views/Tasks')
            ),
            title: 'Tasks',
            icon: 'qf qf-task-list',
          },
          {
            exact: true,
            path: '/applications/events/modialog/:id/messages',
            component: lazy(
              () => import('./service/applications/common/list-views/Messages')
            ),
            title: 'Messages',
            icon: 'qf qf-search',
          },
          {
            exact: true,
            path: '/applications/events/modialog/:id/reports',
            component: lazy(
              () => import('./service/applications/common/list-views/Reports')
            ),
            title: 'Reports',
            icon: 'qf qf-file-information',
          },
          {
            exact: true,
            path: '/applications/events/modialog/:id/statistics',
            component: lazy(
              () => import('./service/applications/dialog/DialogStatistics')
            ),
            title: 'Statistics',
            icon: 'qf qf-bubble-chart',
          },
        ],
      },
      {
        exact: true,
        path: '/applications/verify',
        component: lazy(
          () => import('./service/applications/verify/VerifyView')
        ),
      },
      {
        exact: true,
        path: '/applications/events/verify/:id/copy',
        component: lazy(
          () => import('./service/applications/verify/VerifyView')
        ),
      },
      {
        path: '/applications/events/verify/:id',
        component: lazy(() => import('./service/applications/events/Details')),
        routes: [
          {
            exact: true,
            path: '/applications/events/verify/:id',
            component: lazy(
              () => import('./service/applications/verify/VerifyView')
            ),
            title: 'verify',
            icon: 'qf qf-chat-thread',
          },
          {
            exact: true,
            path: '/applications/events/verify/:id/messages',
            component: lazy(
              () => import('./service/applications/common/list-views/Messages')
            ),
            title: 'Messages',
            icon: 'qf qf-search',
          },
          {
            exact: true,
            path: '/applications/events/verify/:id/statistics',
            component: lazy(
              () => import('./service/applications/verify/VerifyGraphView')
            ),
            title: 'Statistics',
            icon: 'qf qf-bubble-chart',
          },
        ],
      },
      {
        exact: true,
        path: '/applications/rcsconversation',
        component: lazy(
          () => import('./service/applications/rcs/RcsEventView')
        ),
      },
      {
        exact: true,
        path: '/applications/rcsconversation/:draftId/draft',
        component: lazy(
          () => import('./service/applications/rcs/RcsEventView')
        ),
      },
      {
        exact: true,
        path: '/applications/events/rcsconversation/:id/copy',
        component: lazy(
          () => import('./service/applications/rcs/RcsEventView')
        ),
      },
      {
        path: '/applications/events/rcsconversation/:id',
        component: lazy(() => import('./service/applications/events/Details')),
        routes: [
          {
            exact: true,
            path: '/applications/events/rcsconversation/:id',
            component: lazy(
              () => import('./service/applications/rcs/RcsEventView')
            ),
            title: 'rcsconversation',
            icon: 'qf qf-chat-thread',
          },
          // {
          //   exact: true,
          //   path: '/applications/events/rcsconversation/:id/tasks',
          //   component: lazy(
          //     () => import('./service/applications/common/list-views/Tasks')
          //   ),
          //   title: 'Tasks',
          //   icon: 'qf qf-task-list',
          // },
          {
            path: '/applications/events/rcsconversation/:id/messages',
            component: lazy(() => import('./tools/tracking/Nested')),
            title: 'Messages',
            icon: 'qf qf-search-list',
            routes: [
              {
                path: '/applications/events/rcsconversation/:id/messages',
                component: lazy(() => import('./tools/tracking/RcsLogs')),
                exact: true,
                title: 'RCS logs',
                icon: 'qf qf-chat-thread',
              },
              {
                path: '/applications/events/rcsconversation/:id/messages/sms',
                component: lazy(
                  () =>
                    import('./service/applications/common/list-views/Messages')
                ),
                exact: true,
                title: 'SMS logs',
                icon: 'qf qf-search-list',
              },
            ],
          },
        ],
      },
      {
        exact: true,
        guards: [{ policy: 'QAFS_APPLICATION_EVENTS' }],
        path: '/applications/events',
        component: lazy(() => import('./service/applications/events/Events')),
      },
      {
        exact: true,
        guards: [{ policy: 'QAFS_APPLICATION_DRAFTS' }],
        path: '/applications/drafts',
        component: lazy(() => import('./service/applications/main/Drafts')),
      },
      {
        exact: true,
        path: '/system/newsfeed',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/news-feed/NewsFeed')),
      },
      {
        exact: true,
        path: '/system/newsfeed/:id',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(() => import('./system/news-feed/ArticlesView')),
      },
      {
        exact: true,
        path: '/news',
        component: lazy(() => import('./newsfeed/Newsfeed')),
      },
      {
        exact: true,
        path: '/news/:id',
        component: lazy(() => import('./newsfeed/Newsfeed')),
      },
      {
        path: '/system/messagelogs',
        guards: [{ role: ['systemAdmin', 'sysowner'] }],
        component: lazy(
          () => import('./system/message-logs/MessageLogsMaybeTabbed')
        ),
        routes: [
          {
            path: '/system/messagelogs',
            exact: true,
            component: lazy(() => import('./system/message-logs/MessageLogs')),
            title: 'SMS logs',
            icon: 'qf qf-search-list',
          },
          {
            path: '/system/messagelogs/rcs',
            exact: true,
            component: lazy(() => import('./tools/tracking/RcsLogs')),
            title: 'RCS logs',
            icon: 'qf qf-chat-thread',
            routeProps: {
              system: true,
            },
          },
        ],
      },
    ],
  },
]

export const history = createBrowserHistory()

/**
 * Get route
 */
export function getRoute(
  route: IRoute,
  extra: RouteExtraParams,
  index: number
) {
  return (
    <Route
      key={index}
      exact={route.exact}
      path={route.path}
      render={(props) => {
        const propsWithTitle = {
          title: route.title,
          ...props,
          ...(route.routeProps ? route.routeProps : {}),
        }

        return applyGuards({
          ...route,
          props: propsWithTitle,
          extra,
        })
      }}
    />
  )
}

/**
 * Get routes
 */
export function getRoutes(items: IRoute[], extra: RouteExtraParams = {}) {
  return items.map((route, i) => {
    return getRoute(route, extra, i)
  })
}

/**
 * App routes
 */
function AppRoutes() {
  return (
    <Router history={history}>
      <Suspense fallback={<Loading />}>
        <Switch>{getRoutes(routes)}</Switch>
      </Suspense>
    </Router>
  )
}

export default AppRoutes
