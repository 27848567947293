import { z } from 'zod'

import { API } from 'app/api/API'
import { Requests } from 'app/api/Requests'
import {
  availableExternalIntegrationsList,
  customerAvailableIntegrationResponse,
  customerAvailableIntegrationsList,
  externalIntegrationList,
} from 'app/types/zod/common/external-integrations'
import {
  integration,
  IntegrationDetails,
  integrationDetails,
  integrationList,
  integrationResponse,
  integrationSubscription,
} from 'app/types/zod/common/external-integrations/common'
import { parsedRequest } from 'app/types/zod/utils'

export class ExternalIntegrations {
  service: Requests
  system: Requests

  constructor(api: API) {
    this.service = new Requests(api, 'v2/service/')
    this.system = new Requests(api, 'v2/mgmt/')
  }

  getAllExternalIntegrations = parsedRequest(
    externalIntegrationList,
    async () => this.service.get('externalintegrations')
  )

  getAvailableExternalIntegrations = parsedRequest(
    availableExternalIntegrationsList,
    async () => this.service.get('customeravailableintegrations')
  )

  getConnectedIntegrations = parsedRequest(integrationList, async () =>
    this.service.get('commonintegrations')
  )

  getIntegrationDetails = parsedRequest(
    integrationDetails,
    async (id: string) => this.service.get(`commonintegration/${id}`)
  )

  getIntegrationConnectedStatus = parsedRequest(z.boolean(), async (id) => {
    const { connected } = await this.getIntegrationDetails(id)

    return !!connected
  })

  private postIntegration = parsedRequest(
    integrationResponse,
    async (name: string) =>
      this.service.post('commonintegration', { external_integration: name })
  )

  createIntegration = parsedRequest(integration, async (name: string) => {
    const result = await this.postIntegration(name)

    return {
      ...result,
      expiry_time: result.expiry_time.milliseconds,
    }
  })

  updateIntegration = parsedRequest(
    integrationDetails,
    async (id: string, data: Partial<IntegrationDetails>) =>
      this.service.put(`commonintegration/${id}`, data)
  )

  deleteIntegration = async (id: string) =>
    this.service.delete(`commonintegration/${id}`)

  getConnectionSubscriptions = parsedRequest(
    z.array(integrationSubscription),
    async (id: string) =>
      this.service.get(`commonintegrationsubscriptions/${id}`)
  )

  getSystemExternalIntegrations = parsedRequest(
    externalIntegrationList,
    async () => this.system.get('externalintegrations')
  )

  getCustomerAvailableIntegrations = parsedRequest(
    customerAvailableIntegrationsList,
    async (customerId: number) =>
      this.system.get(`companies/${customerId}/externalintegrations`)
  )

  enableCustomerIntegration = parsedRequest(
    customerAvailableIntegrationResponse,
    async ({
      customerId,
      externalIntegrationId,
    }: {
      customerId: number
      externalIntegrationId: number
    }) =>
      this.system.post(`companies/${customerId}/externalintegrations`, {
        external_integration_id: externalIntegrationId,
      })
  )

  disableCustomerIntegration = async (id: number, customerId: number) =>
    this.system.delete(`companies/${customerId}/externalintegrations`, {
      customer_available_integration_id: id,
    })
}
