import { z } from 'zod'

import {
  datetimeStringWithOffset,
  integer,
  nonEmptyString,
} from 'app/types/zod/utils'

export const integrationName = z.enum(['Zapier', 'Pipedream'])

export type IntegrationName = z.infer<typeof integrationName>

export const externalIntegration = z.object({
  id: integer,
  name: integrationName,
  description: nonEmptyString,
  created: datetimeStringWithOffset,
})

export type ExternalIntegration = z.infer<typeof externalIntegration>

export const externalIntegrationList = z.array(externalIntegration)

const availableExternalIntegration = z
  .object({
    id: integer,
    customer: integer,
    enabled: z.boolean(),
    external_integration: externalIntegration,
    created: datetimeStringWithOffset,
  })
  .describe('availableExternalIntegration')

export type AvailableExternalIntegration = z.infer<
  typeof availableExternalIntegration
>

export const customerAvailableIntegrationResponse = z
  .object({
    id: integer,
    customer: integer,
    enabled: z.boolean(),
    external_integration: externalIntegration,
    created: datetimeStringWithOffset,
  })
  .describe('cusomerAvailableIntegrationResponse')

export type CustomerAvailableIntegrationResponse = z.infer<
  typeof customerAvailableIntegrationResponse
>

export const customerAvailableIntegrationsList = z
  .array(customerAvailableIntegrationResponse)
  .describe('customerAvailableIntegrationsList')

export const availableExternalIntegrationsList = z
  .array(availableExternalIntegration)
  .describe('availableExternalIntegrationsList')
